import { Button } from '@noissue-ui-kit/button'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { useAcceptCookies } from '@utils/hooks/useAcceptCookies'
import Image from 'next/legacy/image'
import { USER_LOCATION_POPUP_INTERACTION } from '@utils/constants'
import Cookie from 'js-cookie'
import { useLocationMismatchCheck } from '../Navbar/LocationMismatchPopup/useLocationMismatchCheck'
import { Transition } from '@headlessui/react'

export default function CookiesPopup() {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { locationDoesMismatch } = useLocationMismatchCheck()

  const mismatchPopupWasShown = Boolean(
    Cookie.get(USER_LOCATION_POPUP_INTERACTION)
  )

  // Autmatically accept cookies for all regions except GB
  if (!acceptedCookies && NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY !== 'GB') {
    onAcceptCookies()
  }

  return (
    <Transition
      show={
        !acceptedCookies && (!locationDoesMismatch || mismatchPopupWasShown)
      }
      enter="transition-all durration-250 ease-linear"
      enterFrom="bottom-64"
      enterTo="bottom-0"
      leave="transition-all duration-250 ease-linear"
      leaveFrom="bottom-0"
      leaveTo="bottom-64"
    >
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center p-10 bg-white shadow-2xl md:flex-row md:justify-start md:py-5 font-mori z-above-zendesk-widget">
        <Image src="/images/cookies-icon.png" width={32} height={32} />

        <h3 className="text-[16px] text-boulder text-center md:text-left ml-0 md:ml-5 mt-6 mb-9 md:my-0">
          We use cookies in order to personalise your site experience.
        </h3>

        <div className="flex w-full ml-0 md:ml-auto md:w-max">
          <Button
            Component={'a'}
            href="/privacy-policy/?tab=privacy"
            size="s"
            variant="outline"
            colour="acai"
            corners="rounded"
            className="w-full mr-6 md:w-max"
          >
            <span className="whitespace-nowrap">Privacy Policy</span>
          </Button>

          <Button
            size="s"
            colour="starfruit"
            corners="rounded"
            className="w-full"
            onClick={() => onAcceptCookies()}
          >
            Allow
          </Button>
        </div>
      </div>
    </Transition>
  )
}
